import React, { useState } from "react"
import { Link } from "gatsby"
import NavBar from "../components/NavBar"
import Metatags from "../components/metatags"
import Footer from "../components/Footer.es"
import { sendContact } from "../services/contact"

import "../css/contact.css"

const regExEmail = new RegExp(
  '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
)

const SpareParts = ({ location }) => {
  const [userName, setUserName] = useState("")
  const [company, setCompany] = useState("")
  const [subject, setSubjectNo] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [errorFields, setErrorFields] = useState([])

  const [showSpinner, setShowSpinner] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [sendDisabled, setSendDisabled] = useState(true)

  const changeUserName = evt => {
    setUserName(evt.target.value)
    if (evt.target.value !== "") {
      removeError("userName")
    }
  }

  const changeCompany = evt => {
    setCompany(evt.target.value)
    if (evt.target.value !== "") {
      removeError("company")
    }
  }

  const changeSubject = evt => {
    setSubjectNo(evt.target.value)
    if (evt.target.value !== "") {
      removeError("subject")
    }
  }

  const changePhoneNumber = evt => {
    setPhoneNumber(evt.target.value)
    if (evt.target.value !== "") {
      removeError("phoneNumber")
    }
  }

  const changeEmail = evt => {
    setEmail(evt.target.value)
    if (regExEmail.test(evt.target.value)) {
      removeError("email")
    }
  }

  const changeMessage = evt => {
    setMessage(evt.target.value)
    if (evt.target.value !== "") {
      removeError("message")
    }
  }

  const sendEmail = evt => {
    verifyFields()
  }

  const removeError = errName => {
    if (errorFields.includes(errName)) {
      let errorFieldAux = errorFields
      errorFieldAux.splice(errorFieldAux.indexOf(errName), 1)
      setErrorFields(errorFieldAux)
    }
  }

  const verifyFields = () => {
    let errorFieldAux = []
    if (userName === "") {
      errorFieldAux.push("userName")
    }
    if (!regExEmail.test(email)) {
      errorFieldAux.push("email")
    }
    if (company === "") {
      errorFieldAux.push("company")
    }
    if (subject === "") {
      errorFieldAux.push("subject")
    }
    if (phoneNumber === "") {
      errorFieldAux.push("phoneNumber")
    }
    if (message === "") {
      errorFieldAux.push("message")
    }
    if (errorFieldAux.length !== 0) {
      setErrorFields(errorFieldAux)
      scrollToError(errorFieldAux[0])
    } else {
      handleSendContact()
      // this.setState({
      //   spinnerActive: true
      // })
    }
  }

  const scrollToError = filedId => {
    const element = document.getElementById(filedId)
    const screenPosition = element.getBoundingClientRect()
    window.scrollTo({
      top: window.scrollY + screenPosition.top - 126,
      left: 0,
      behavior: "smooth",
    })
  }

  const handleSendContact = async () => {
    const data = {
      userName: userName,
      company: company,
      subject: subject,
      phoneNumber: phoneNumber,
      email: email,
      message: message,
    }
    setShowSpinner(true)
    setShowModal(true)
    await sendContact(data).then(res => {
      setShowSpinner(false)
      console.log(res)
    })
  }
  const closeModal = () => {
    setShowModal(false)
    setUserName("")
    setCompany("")
    setSubjectNo("")
    setPhoneNumber("")
    setEmail("")
    setMessage("")
  }

  const changeDisabled = e => {
    setSendDisabled(!sendDisabled)
  }

  return (
    <>
      <Metatags
        title="Multilógica | Contacto"
        description="Proveedor de maquinaria para colchones"
        url="https://newsite.multilogica.ind.br/es/contacto"
        image="https://newsite.multilogica.ind.br/Multilogica-icon.png"
        keywords="colchón, máquina de coser, mammut, beckmann sew"
        language="es"
      />
      <div className={`${showModal ? "block" : "hidden"}`}>
        <div
          id="defaultModal"
          className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full "
        >
          <div className="relative p-4 w-full max-w-2xl h-full md:h-auto m-auto">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="p-6 space-y-6">
                <p className=" text-xl leading-relaxed text-gray-500 dark:text-gray-400">
                  Tu correo electrónico fue enviado correctamente. Gracias.
                </p>
              </div>
              <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                <button
                  onClick={env => closeModal()}
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NavBar location={location["pathname"]} />
      <div className="bg-mi-blue">
        <div className="container mx-auto">
          <div className="h-20" />
          <div className="max-w-xl mx-auto text-center">
            <div
              className="text-white text-4xl font-semibold mx-4"
              itemProp="headline"
            >
              Contacto
            </div>
          </div>
          <div className="h-20" />
        </div>
      </div>
      <div className="h-24" />
      <div className="grid grid-cols-1 md:grid-cols-10 gap-2">
        <div className="col-span-0 md:col-span-1" />
        <div className="col-span-0 md:col-span-8">
          <div className="grid grid-cols-2 md:grid-cols-2">
            <div className="col-span-2 mx-2">
              <div className="w-full  mb-6 md:mb-0">
                <label className="block text-base mb-2" htmlFor="grid-name">
                  Nombre
                </label>
                <input
                  className={`${
                    errorFields.includes("userName") ? "border-red-800" : ""
                  } appearance-none block w-full bg-gray-200 text-gray-700 border-2  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                  id="userName"
                  type="text"
                  onChange={env => changeUserName(env)}
                  value={userName}
                />
              </div>
            </div>
            <div className="col-span-2 md:col-span-1 mx-2">
              <div className="w-full pr-0 md:pr-1 mb-6 md:mb-0">
                <label className="block text-base mb-2" htmlFor="grid-name">
                  email
                </label>
                <input
                  className={`${
                    errorFields.includes("email") ? "border-red-800" : ""
                  } appearance-none block w-full bg-gray-200 text-gray-700 border-2  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                  id="email"
                  type="text"
                  onChange={env => changeEmail(env)}
                  value={email}
                />
              </div>
            </div>
            <div className="col-span-2 md:col-span-1 mx-2">
              <div className="w-full pl-0 md:pl-1 mb-6 md:mb-0">
                <label className="block text-base mb-2" htmlFor="grid-name">
                  Empresa
                </label>
                <input
                  className={`${
                    errorFields.includes("company") ? "border-red-800" : ""
                  } appearance-none block w-full bg-gray-200 text-gray-700 border-2  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                  id="company"
                  type="text"
                  onChange={env => changeCompany(env)}
                  value={company}
                />
              </div>
            </div>
            <div className="col-span-2 md:col-span-1 mx-2">
              <div className="w-full pr-0 md:pr-1 mb-6 md:mb-0">
                <label className="block text-base mb-2" htmlFor="grid-name">
                  Tema
                </label>
                <input
                  className={`${
                    errorFields.includes("subject") ? "border-red-800" : ""
                  } appearance-none block w-full bg-gray-200 text-gray-700 border-2  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                  id="subject"
                  type="text"
                  onChange={env => changeSubject(env)}
                  value={subject}
                />
              </div>
            </div>
            <div className="col-span-2 md:col-span-1 mx-2">
              <div className="w-full pl-0 md:pl-1 mb-6 md:mb-0">
                <label className="block text-base mb-2" htmlFor="grid-name">
                  Teléfono
                </label>
                <input
                  className={`${
                    errorFields.includes("phoneNumber") ? "border-red-800" : ""
                  } appearance-none block w-full bg-gray-200 text-gray-700 border-2  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                  id="phoneNumber"
                  type="text"
                  onChange={env => changePhoneNumber(env)}
                  value={phoneNumber}
                />
              </div>
            </div>

            <div className="col-span-2 mx-2">
              <div className="w-full pl-0 md:pl-1 mb-6 md:mb-0">
                <label className="block text-base mb-2" htmlFor="grid-name">
                  Mensaje
                </label>
                <textarea
                  className={`${
                    errorFields.includes("message") ? "border-red-800" : ""
                  } appearance-none block w-full bg-gray-200 text-gray-700 border-2  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                  id="message"
                  type="text"
                  onChange={env => changeMessage(env)}
                  value={message}
                  rows={4}
                />
              </div>
            </div>
            <div className="col-span-2 mx-2">
              <div className="pl-0 md:pl-1 mb-1 md:mb-0 float-left ">
                <label
                  htmlFor="default-toggle"
                  className="inline-flex relative items-center cursor-pointer"
                >
                  <input
                    type="checkbox"
                    value=""
                    id="default-toggle"
                    className="sr-only peer"
                    onChange={evt => changeDisabled(evt)}
                  />
                  <div className="w-11 h-6 bg-gray-200 rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-mi-blue" />
                </label>
              </div>
              <div className="text-base float-left contact-text">
                Al enviar esta información acepto la{" "}
                <Link to="/es/politica-de-privacidad">
                  <span className="underline">política de privacidad</span>
                </Link>
                .
              </div>
            </div>

            <div className="col-span-2 md:col-span-2">
              <div className="block py-3 ">
                <button
                  className={`${
                    sendDisabled
                      ? "text-mi-yellow text-lg bg-mi-blue bg-opacity-60 py-2 px-4 rounded-full  float-right mx-2"
                      : "text-mi-yellow text-lg bg-mi-blue py-2 px-4 rounded-full hover:bg-mi-light-blue float-right mx-2"
                  }`}
                  onClick={evt => sendEmail(evt)}
                  disabled={sendDisabled}
                >
                  enviar
                </button>
                <div
                  role="status"
                  className={`${showSpinner ? "" : "hidden"} mt-1 float-right`}
                >
                  <svg
                    aria-hidden="true"
                    className="mr-2 w-8 h-8 text-sc-gray animate-spin fill-yellow-400"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#00314f"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#ffffff"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-12" />
      <Footer />
    </>
  )
}

export default SpareParts
